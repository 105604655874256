export default (s: any, allWords = false) => {
  if (typeof s !== 'string') return ''

  if (allWords) {
    return s
      .split(' ')
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join(' ')
  }

  return s.charAt(0).toUpperCase() + s.slice(1)
}
